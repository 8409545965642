import React from "react"
import { Link } from "gatsby"
import SanityImage from "./sanityImage"
import Img from "gatsby-image"


const UtilityStoryitem = ({image,headline,value,slug}) => {
  /*const data = useStaticQuery(graphql`
    query testQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)*/

  return (
    <>
      {/* Post Classic*/}
      <div className="post-classic">
        {value && value.toString().length > 0 &&
          <div style={{transform:'scale(0.6)',right:'-28px',top:'-28px'}} className="post-classic-badge">Verdi
            <div className="post-classic-price-value">{value}</div>
          </div>
        }
        <div className="post-classic-thumbnail">
          <Link to={"/"+slug+"/"}>
            <figure className="post-classic-figure">
              {/*<img src={image.src} alt={headline} width={270} height={155}/>*/}
              <SanityImage alt={headline} image={image} width={270} height={180}/>
            </figure>
          </Link>
          <div style={{textAlign:'center'}} className="post-classic-caption"><Link className="heading-5" to={"/"+slug+"/"}>{headline}</Link></div>
        </div>
      </div>
    </>
  )
}

export default UtilityStoryitem
