import React, { useRef, useLayoutEffect, useState } from "react";
import Image from "gatsby-plugin-sanity-image"


const SanityImage = ({image, height, width}) => {

  //console.log(image)

  return (
      <Image
          {...image}
          width={width}
          height={height}
          style={{
            width: width+'px',
            height: height+'px',
          }}
      />
  )
}

/*
const SanityImage = props => {

  // https://www.sanity.io/docs/presenting-images
  // https://github.com/sanity-io/image-url
  // https://css-tricks.com/five-interesting-ways-to-use-sanity-io-for-image-art-direction/
  // https://www.sanity.io/docs/image-urls#fit-object-object
  // https://css-tricks.com/almanac/properties/o/object-position/


  const targetRef = useRef();
  const [initialComponentDimensions, setInitialComponentDimensions] = useState({ width:0, height: 0 });

  const image = props.image

  //console.log(image)

  useLayoutEffect(() => {
    if (targetRef.current) {
      setInitialComponentDimensions({
        width: targetRef.current.offsetWidth,
        height: targetRef.current.offsetHeight
      });
    }
  }, []);

  // Check dimensions
  let selfHeight = false
  let selfWidth = false
  let selfMaxHeight = false
  let selfMaxWidth = false
  let selfMinHeight = false
  let selfMinWidth = false

  let selfStyle = {}

  if(props.height){
    selfHeight = props.height
    selfStyle.height = selfHeight
  }
  if(props.width){
    selfWidth = props.width
    selfStyle.width = selfWidth
  }
  if(props.maxWidth){
    selfMaxWidth = props.maxWidth
    selfStyle.maxWidth = selfMaxWidth
  }
  if(props.maxHeight){
    selfMaxHeight = props.maxHeight
    selfStyle.maxHeight = selfMaxHeight
  }
  if(props.minWidth){
    selfMinWidth = props.minWidth
    selfStyle.minWidth = selfMinWidth
  }
  if(props.minHeight){
    selfMinHeight = props.minHeight
    selfStyle.minHeight = selfMinHeight
  }

  if(!selfWidth && initialComponentDimensions.width > 0){
    selfWidth = initialComponentDimensions.width;
    if(selfMaxWidth && selfWidth > selfMaxWidth){
      selfWidth = selfMaxWidth
    }
  }



  let imageOriginalWidth = image.asset._rawMetadata.dimensions.width
  let imageOriginalHeight = image.asset._rawMetadata.dimensions.height
  let imageOriginalAspectRatio = imageOriginalWidth/imageOriginalHeight
  let imageOriginalLandscape = true
  if(imageOriginalAspectRatio < 1){
    imageOriginalLandscape = false
  }

  let imageHeight = imageOriginalHeight
  let imageWidth = imageOriginalWidth
  let imageAspectRatio = imageWidth/imageHeight
  let imageLandscape = true

  if(image.crop && (image.crop.top != 0 || image.crop.left != 0 || image.crop.right != 0 || image.crop.bottom != 0)) {
    imageHeight = Math.round((image.crop.top + image.crop.bottom) * imageHeight)
    imageWidth = Math.round((image.crop.right + image.crop.left) * imageWidth)
    imageAspectRatio = imageWidth/imageHeight
  }

  if(imageAspectRatio < 1){
    imageLandscape = false
  }

  let imageHotspotHeight = false
  let imageHotspotWidth = false
  let imageHotspotX = false
  let imageHotspotY = false
  let imageHotspotAspectRatio = false
  let imageHotspotLandscape = true

  if(image.hotspot) {
    imageHotspotHeight = Math.round((image.hotspot.height) * imageOriginalHeight)
    imageHotspotWidth = Math.round((image.hotspot.width) * imageOriginalWidth)
    imageHotspotX = Math.round( imageOriginalWidth*image.hotspot.x)
    imageHotspotY = Math.round(imageOriginalHeight*image.hotspot.y)
    imageHotspotAspectRatio = imageHotspotWidth/imageHotspotHeight
  }

  if(imageHotspotAspectRatio < 1){
    imageHotspotLandscape = false
  }



  const getImageUrl = () => {
    let imageUrl = '';
    return imageUrl;
  }

  const getImageString = () => {
    let imgString = "?"
    if(selfWidth){
     imgString += 'w='+selfWidth+'&'
    }
    imgString += getCropString()
    return imgString
  }

  const getCropString = () => {
    let cropString = "";

    if(image.crop && (image.crop.top != 0 || image.crop.left != 0 || image.crop.right != 0 || image.crop.bottom != 0)){
      imageHeight = Math.round((image.crop.top + image.crop.bottom)*imageHeight)
      imageWidth =  Math.round((image.crop.right + image.crop.left)*imageWidth)

      cropString = "rect="+Math.round(image.crop.left*imageOriginalWidth)+","+Math.round(image.crop.top*imageOriginalHeight)
      cropString += ","+Math.round(imageOriginalWidth-imageOriginalWidth*(image.crop.left+image.crop.right))+","+Math.round(imageOriginalHeight-imageOriginalHeight*(image.crop.top+image.crop.bottom))
    }

    return cropString;
  }

  return (
    <div ref={targetRef} style={{border:0,padding:0,margin:0,overflow:'hidden',width:'inherit',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',textAlign:'center'}}>
      <img alt={props.alt} style={{alignSelf:'center',justifySelf:'center',display:'block!important',...selfStyle}} src={props.image.asset.url+getImageString()}/>
    </div>
  );
};*/

export default SanityImage
